<template>
  <section>
    <b-col>
      <b-card class="mb-2">
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-if="isAdmin || isCashier"
          variant="primary"
          @click="$router.push({ name: 'create-menu' })"
          class="d-flex align-items-center">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Nuevo</span>
        </b-button>
      </div>
      </b-card>
      <div v-if="item.length > 0">
        <div v-for="(i, x) in item" :key="x">
        <h4>{{ i.school.name }}</h4>
        <h3>{{ i.description }}</h3>
          <b-row>
            <b-col v-for="(i, x) in i.days" :key="x">
              <b-card class="mb-2" style="height: 155px;">
                <h2>{{ days(i.day) }} </h2>
                <p>
                  Disponibles {{ i.stock }} 
                </p>
                <p>
                  {{ i.name }} 
                </p>
                <strong>
                  {{ i.price | currency({ symbol: '$', precision: 2 }) }}
                </strong>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else>
        <h3>{{ item.description }}</h3>
        <b-row>
          <b-col v-for="(i, x) in item.days" :key="x">
            <b-card class="mb-2" style="height: 155px;">
              <h2>{{ days(i.day) }} </h2>
              <p>
                Disponibles {{ i.stock }} 
              </p>
              <p>
                {{ i.name }} 
              </p>
              <strong>
                {{ i.price | currency({ symbol: '$', precision: 2 }) }}
              </strong>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BButton,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BLink,
  },
  data() {
    return {
      loading: false,
      search: null,
      item: {},
      description: null
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['user/userData'].roles.find(role => role.name == 'admin')
    },
    isCashier() {
      return this.$store.getters['user/userData'].roles.find(role => role.name == 'cashier')
    },
    user() {
      return this.$store.getters['user/userData']
    },
  },
  methods: {
    async getItems() { 
      try {
        this.loading = true
        this.item = await this.$store.dispatch('menu/index', this.isAdmin ? 'all' : this.user.school_id)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    days(day){
      const arr = [
        'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'
      ]
      return arr[day-1]
    },
    getDesc(string) {
      this.description =  string
    }
  },
  async mounted() {
    this.getItems()
  },
}
</script>

<style>

</style>